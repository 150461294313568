@import 'variables';

$prefix: $antPrefix + -collapse;

.#{$prefix} {
  color: $textColor;
  line-height: 35px;
  background-color: $componentBackground;

  &-borderless {
    & > .#{$prefix}-item > .#{$prefix}-content {
      background: transparent;
    }
  }

  & > .#{$prefix}-item > .#{$prefix}-header {
    color: $textColor;
    align-items: center;
  }

  &-content {
    color: $textColor;
    background: transparent;
  }
}

.#{$prefix}-content-box .#{$prefix}-content-box {
  padding: 0;
}
