@import 'variables';

$prefix: $antPrefix + -steps;

.#{$prefix} {
  color: $textColor;

  .#{$prefix}-item-title {
    color: $textColor;

    &::after {
      background: $contrastBackground;
    }
  }

  &-item-process > .#{$prefix}-item-container {
    & > .#{$prefix}-item-icon {
      background: $primaryColor;
      border-color: $primaryColor;
    }

    & > .#{$prefix}-item-content {
      .#{$prefix}-item-title {
        color: $primaryColor;

        &::after {
          background-color: $borderColor;
        }
      }

      .#{$prefix}-item-subtitle {
        color: $textColor;
      }
    }

    & > .#{$prefix}-item-tail::after {
      background-color: $borderColor;
    }
  }

  &-item-wait {
    .#{$prefix}-item-icon {
      background-color: $bodyBackground;
      border-color: $disabledColor;

      & > .#{$prefix}-icon {
        color: $disabledColor;
      }
    }

    & > .#{$prefix}-item-container > .#{$prefix}-item-content {
      & > .#{$prefix}-item-title {
        color: $disabledColor;

        &::after {
          background-color: $borderColor;
        }
      }

      .#{$prefix}-item-subtitle {
        color: $textColor;
      }
    }

    & > .#{$prefix}-item-container .#{$prefix}-item-tail::after {
      background-color: $borderColor;
    }
  }

  &-item-finish {
    .#{$prefix}-item-icon {
      background-color: $bgPrimaryColor;
      border-color: $primaryColor;

      > .#{$prefix}-icon {
        color: $primaryColor;
      }
    }

    & > .#{$prefix}-item-container > .#{$prefix}-item-content {
      & > .#{$prefix}-item-title,
      .#{$prefix}-item-subtitle {
        color: $primaryColor;
      }

      & > .#{$prefix}-item-title::after {
        background-color: $primaryColor;
      }
    }
  }
}

.#{$prefix}-vertical {
  .#{$prefix}-item-finish {
    .#{$prefix}-item-icon > .#{$prefix}-icon .#{$prefix}-icon-dot,
    > .#{$prefix}-item-container > .#{$prefix}-item-tail::after {
      background: $primaryColor;
    }
  }

  .#{$prefix}-item-process {
    .#{$prefix}-item-icon > .#{$prefix}-icon .#{$prefix}-icon-dot,
    > .#{$prefix}-item-container > .#{$prefix}-item-tail::after {
      background: $primaryColor;
    }
  }
}
