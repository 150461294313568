@import 'variables';

$prefix: $antPrefix + -form;

.#{$prefix} {
  &-item-label > label {
    color: $textColor;
    white-space: normal;
  }

  &-item-control:not(.has-error) {
    .#{$prefix}-explain {
      color: $disabledColor;
    }
  }

  &-item {
    margin-bottom: 10px;
  }
}

@media (max-width: 992px) {
  .#{$prefix}-horizontal {
    .#{$prefix}-item-label.#{$antPrefix}-col-lg-10 {
      text-align: left;
    }
  }
}
