@import 'variables';

.calendar-wrapper {
    border-radius: 0;
    border: 0;
}

@media screen and (max-width: 1600px) {  
    .calendar-wrapper {
        .weekly-calendar {
            .#{$antPrefix}-table-tbody > tr > td, .#{$antPrefix}-table-thead > tr > th {
                padding: 16px 8px;
            }
        }
    } 
}