@import 'variables';

$prefix: $antPrefix + -tabs;

.#{$prefix} {
  color: $textColor;

  > .#{$prefix}-nav::before,
  &-bar {
    border-bottom-color: $bodyBackground;
  }

  &-content {
    color: $textColor;
  }

  &-nav .#{$prefix}-tab {
    &-active,
    &:hover,
    &-active .#{$prefix}-tab-btn {
      color: $primaryColor;
    }

    &-disabled,
    &-disabled:hover {
      color: $disabledColor;
    }
  }

  &-ink-bar {
    background-color: $primaryColor;
  }
}

.createupdate-tabs .ant-tabs-nav-operations {
  display: none;
  visibility: hidden !important;
  position: absolute !important;
}

.createupdate-tabs .ant-tabs-nav-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab {
    flex: 2 0 auto;
    justify-content: center;
    margin: 0;
  }

  .ant-tabs-tab:nth-child(1) .ant-tabs-tab-btn {
    color: #30972e;
  }

  .ant-tabs-tab:nth-child(1).ant-tabs-tab-active {
    border-bottom: 1px solid #30972e;
  }

  .ant-tabs-tab:nth-child(2) .ant-tabs-tab-btn {
    color: #629ad2;
  }

  .ant-tabs-tab:nth-child(2).ant-tabs-tab-active {
    border-bottom: 1px solid #629ad2;
  }
  .ant-tabs-tab:nth-child(3) .ant-tabs-tab-btn {
    color: #f8ac18;
  }

  .ant-tabs-tab:nth-child(3).ant-tabs-tab-active {
    border-bottom: 1px solid #f8ac18;
  }
  .ant-tabs-tab:nth-child(4) .ant-tabs-tab-btn {
    color: #ff483c;
  }

  .ant-tabs-tab:nth-child(4).ant-tabs-tab-active {
    border-bottom: 1px solid #ff483c;
  }

  .ant-tabs-tab:nth-child(5) .ant-tabs-tab-btn {
    color: #30972e;
  }

  .ant-tabs-tab:nth-child(5).ant-tabs-tab-active {
    border-bottom: 1px solid #30972e;
  }
}
