@import 'variables';

$prefix: $antPrefix + -typography;

.#{$prefix},
h1.#{$prefix},
h2.#{$prefix},
h3.#{$prefix},
h4.#{$prefix},
h5.#{$prefix},
h6.#{$prefix} {
  color: $textColor;
}

h4.#{$prefix} {
  font-size: 18px;
}
