@import 'variables';

$prefix: $antPrefix + -timeline;

.#{$prefix} {
    .#{$prefix}-item-tail {
        height: 100%;
        inset-inline-start: 3.5px;
    }

    .#{$prefix}-item-head {
       height: 9px;
       width: 9px;
    }

    .#{$prefix}-item-head-blue {
        color: $primaryColor;
        border-color: $primaryColor;
        background-color: $primaryColor;
    }
}