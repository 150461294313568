@import 'variables';

$prefix: $antPrefix + -picker;

%disabled {
  //cursor: not-allowed;
  color: $disabledColor;
  background-color: $bodyBackground;
  border-color: $borderColor;
  .#{$prefix}-suffix {
    color: $disabledColor;
  }
  .#{$prefix}-separator {
    &::before,
    &::after {
      border-color: inherit;
    }
  }
  .#{$prefix}-input {
    background: inherit;
    & > input {
      color: inherit;
    }
  }
}

.#{$prefix}-calendar {
  color: $textColor;

  .#{$prefix}-panel {
    border-color: $borderColor;
    &-container {
      background: $pickerDropdown;
      .#{$prefix}-header {
        color: $textColor;
        border-color: $borderColor;
        .#{$prefix}-header-view {
          button:hover {
            color: $primaryColor;
          }
        }

        > button {
          color: $textColorA075;
          &:hover {
            color: $textColor;
          }
        }
      }
    }
    .#{$prefix}-footer {
      border-top-color: $borderColor;
      .#{$prefix}-today-btn {
        color: $primaryColor;
      }
    }
  }

  .#{$prefix}-cell-in-view {
    color: $textColor;
  }

  .#{$prefix}-cell:not(.#{$prefix}-cell-in-view) {
    color: $disabledColor;
  }

  .#{$prefix}-cell-today:not(.#{$prefix}-cell-selected) {
    color: $primaryColor;

    .#{$prefix}-cell-inner {
      border-color: $primaryColor;
      background: $bgPrimaryColor !important;
    }

    .#{$prefix}-calendar-date-today .#{$prefix}-calendar-date-value {
      color: $primaryColor;
    }
  }

  .#{$prefix}-cell-selected {
    color: $secondaryColor;

    .#{$prefix}-cell-inner {
      border-color: $secondaryColor;
      background: $itemActiveBackground !important;
    }

    .#{$prefix}-calendar-date .#{$prefix}-calendar-date-value,
    .#{$prefix}-calendar-date-today .#{$prefix}-calendar-date-value {
      color: $secondaryColor !important;
    }
  }

  .#{$prefix}-content th {
    color: $textColor;
  }

  .#{$prefix}-cell-disabled::before {
    background: $disabledColor;
  }

  .#{$prefix}-ranges .#{$prefix}-preset > .#{$antPrefix}-tag-blue {
    color: $primaryColor;
    border-color: $primaryColor;
    background: $itemHoverBackground;
  }

  .#{$prefix}-week-panel-row:hover td {
    background: $primaryColor;
  }
  .#{$prefix}-week-panel-row-selected {
    &,
    &:hover {
      td {
        background: $primaryColor;
      }
    }
  }
  .#{$prefix}-range-arrow::after {
    border-color: $pickerDropdown;
  }
}

.#{$prefix} {
  &-range .#{$prefix}-active-bar {
    background: $primaryColor;
  }
  &:not(.#{$prefix}-disabled) {
    color: $textColor;
    background-color: $componentBackground;
    border-color: $borderColor;
    .#{$prefix}-input {
      background: inherit;
      & > input {
        color: inherit;
        &:disabled {
          color: $disabledColor;
        }
      }
    }
    .#{$prefix}-suffix {
      color: $textColor;
    }
    .#{$prefix}-clear {
      color: inherit;
      background: inherit;
      &:hover {
        color: $primaryColor;
      }
    }
  }
  &:hover,
  &-focused {
    &:not(.#{$prefix}-disabled) {
      border-color: $primaryColor;
    }
  }
  &.#{$prefix}-disabled {
    @extend %disabled;
  }
}

.#{$antPrefix}-form-item .#{$prefix} {
  min-width: 180px;
}

.#{$prefix}-panel-container {
  .#{$prefix}-now-btn {
    color: $primaryColor;
  }

  .#{$prefix}-header-view {
    .#{$prefix}-month-btn:hover,
    .#{$prefix}-year-btn:hover {
      color: $secondaryColor;
    }
  }

  .#{$prefix}-cell-in-view.#{$prefix}-cell-selected:not(
      .#{$prefix}-cell-disabled
    )
    .#{$prefix}-cell-inner {
    background: $secondaryColor;
  }

  .#{$prefix}-cell-in-view.#{$prefix}-cell-today
    .#{$prefix}-cell-inner::before {
    border-color: $secondaryColor;
  }
}
