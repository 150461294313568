@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;
  box-shadow: none;

  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }

  &-head {
    border-bottom: 0;
    padding: 16px 16px 10px;
    min-height: unset;

    .#{$prefix}-head-title {
      color: $primaryColor;
      font-size: 18px;
      font-weight: 600;
    }
  }

  &-bordered {
    border-color: $borderPrimaryColor;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  }

  &-body {
    padding: 10px 16px 16px;
  }
}

@media (min-width: 1200px) {
  .#{$antPrefix}-col:not(.#{$antPrefix}-col:only-child):not(
      .#{$antPrefix}-col-24
    )
    > .#{$prefix}:only-child {
    height: 100%;
    margin-bottom: 16px;
  }
}

.statistics-card.#{$prefix} {
  width: 100%;

  .#{$prefix}-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .statistics-card-title,
    .statistics-card-count {
      font-size: 18px;
    }

    .statistics-card-percentage,
    .statistics-card-count {
      color: $primaryColor;
    }

    .statistics-card-percentage {
      font-size: 24px;
    }

    .statistics-card-count {
      margin-bottom: 0;
    }
  }
}
