@import 'variables';

$prefix: $antPrefix + -transfer;

.#{$prefix} {
    .#{$prefix}-list-content {
        .#{$prefix}-list-content-item {
            .#{$prefix}-list-content-item-remove {
                color: $disabledColor;

                &:hover {
                    color: $primaryColor;
                }
            }
        }

        .#{$prefix}-list-content-item-checked {
            background: $itemActiveBackground;

            &:hover {
                background: $itemActiveBackground;
            }
        }
    }
}