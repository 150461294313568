@import 'variables';

$prefix: $antPrefix + -page-header;

.#{$prefix} {
  color: $textColor;
  background: $componentBackground;
  padding: 0 0 16px;

  & > .#{$antPrefix}-breadcrumb a:not(:hover),
  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    color: $textColorSecondary;
  }

  & > .#{$antPrefix}-breadcrumb > span:last-child a {
    cursor: auto;
  }

  .#{$antPrefix}-breadcrumb-separator {
    color: $textColorSecondary;
  }

  .#{$antPrefix}-breadcrumb .#{$antPrefix}-breadcrumb-link,
  .#{$antPrefix}-breadcrumb .#{$antPrefix}-breadcrumb-link a {
    &:hover {
      background: transparent;
      color: $primaryColor;
    }
  }

  .#{$prefix}-breadcrumb + .#{$prefix}-heading {
    margin-block-start: 0;
  }

  .#{$prefix}-heading {
    .#{$prefix}-heading-left {
      .#{$prefix}-heading-title {
        color: $primaryColor;
        display: inline-flex;
        align-items: center;
      }
      .#{$prefix}-back .#{$prefix}-back-button {
        color: $primaryColor;
        &:hover,
        &:focus {
          color: $primaryColor;
        }
      }
    }
  }
}
