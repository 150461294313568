@import 'variables';

$prefix: $antPrefix + -menu;

.#{$prefix}-light,
.#{$prefix}-inline,
.#{$prefix}-vertical,
.#{$prefix}-root.#{$prefix}-inline {
  border-right: transparent;
  border-inline-end: none;

  .#{$prefix}-item {
    width: 100%;
    border-radius: 0;

    a {
      color: $textColorSecondary;
    }

    &:hover,
    &:focus {
      background: $itemHoverBackground;

      a {
        color: $textColor;
      }
    }
  }

  .#{$prefix}-item-selected {
    border-right: 8px solid $secondaryColor;
    background: $itemActiveBackground;
  }

  .#{$prefix}-submenu-title {
    border-radius: 0;
    width: 100%;
    color: $textColorSecondary;

    &:hover,
    &:focus {
      background: $itemHoverBackground;
      color: $textColor;
    }
  }

  .#{$prefix}-submenu-selected > .#{$prefix}-submenu-title {
    color: $primaryColor;
  }
}

.#{$prefix}-submenu > .#{$prefix} {
  color: $textColor;
  background: $contrastBackground;
}

.#{$prefix}-submenu-inline
  > .#{$prefix}-submenu-title
  .#{$prefix}-submenu-arrow {
  &::before,
  &::after {
    background-image: linear-gradient(to right, $textColor, $textColor);
  }
}

.#{$prefix}-submenu-selected
  > .#{$prefix}-submenu-title
  .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu-open > .#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu
  > .#{$prefix}-submenu-title:hover
  .#{$prefix}-submenu-arrow {
  &::before,
  &::after {
    background-image: linear-gradient(to right, $primaryColor, $primaryColor);
  }
}

.#{$antPrefix}-tooltip.#{$prefix}-inline-collapsed-tooltip.#{$antPrefix}-tooltip-placement-right {
  .#{$antPrefix}-tooltip-content {
    .#{$antPrefix}-tooltip-inner {
      a {
        .anticon {
          margin-right: 6px;
        }
      }
    }
  }
}
