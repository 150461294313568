@import 'variables';

// Scrollbar styles for Chromium browsers (Chrome, Edge, Arc...)
*::-webkit-scrollbar {
  width: 8px;
}

// Scrollbar styles for Firefox
* {
  scrollbar-width: thin;
}
